require.config({
    baseUrl: 'js', // Relative to index

    paths: {
        // node modules
        lodash: '../node_modules/lodash/lodash',
        uuid: '../node_modules/node-uuid/uuid',
        axios: '../node_modules/axios/dist/axios',
        moment: '../node_modules/moment/min/moment.min',
        momentTimezone: '../node_modules/moment-timezone/builds/moment-timezone-with-data-2010-2020.min',
        Graph: '../node_modules/@tvg/graph/dist/tvg-graph.mod.umd',
        DepositGTM: '../node_modules/@tvg/deposit-gtm/build/bundle',
        Deposit: '../node_modules/@tvg/deposit/modules',
        Mediator: '../node_modules/@tvg/mediator-classic/dist/@tvg/mediator-classic.umd',
        Registration: '../node_modules/@tvg/registration/tvg-registration.min',
        DMAInjector: '../node_modules/@tvg/dma-injector/dist/tvg.bundle',
        DOMPurify: '../node_modules/dompurify/dist/purify',

        //Vendors
        angular: 'vendors/angular/angular.min',
        angularTouch: 'vendors/angular-touch/angular-touch.min',
        angularRoute: 'vendors/angular-route/angular-route.min',
        angularSanitize: 'vendors/angular-sanitize/angular-sanitize.min',
        angularAnimate: 'vendors/angular-animate/angular-animate.min',
        ngMd5: 'vendors/angular-md5/angular-md5.min',
        uibootstrap: 'vendors/angular-bootstrap/ui-bootstrap.min',
        uibootstraptpls: 'vendors/angular-bootstrap/ui-bootstrap-tpls.min',
        angularCookies: 'vendors/angular-cookies/angular-cookies.min',
        angularCookie: 'vendors/angular-cookie/angular-cookie.min',
        angularUTF8Base64: 'vendors/angular-utf8-base64/angular-utf8-base64.min',
        muton: 'vendors/muton/muton.window',
        Configuration: 'vendors/tvg-configuration/configurationMod.min',
        Content: 'vendors/tvg-content/contentMod.min',
        PasswordRecovery: 'vendors/tvg-password-recovery/dist/module.min',
        matchMedia: 'vendors/matchMedia/matchMedia',
        ngDialog: 'vendors/ng-dialog/js/ngDialog',
        angularFilter: 'vendors/angular-filter/dist/angular-filter',
        TVGCommon: 'vendors/tvg-common/dist/TVGCommon.umd.min',

        //App core module
        appDesktop: 'desktop/app',
        AppCore: 'common/modules/AppCore/appCoreMod',
        AppRootFac: 'common/modules/AppCore/providers/appRootFac',
        AppRequestInterceptor: 'common/modules/AppCore/providers/appRequestInterceptor',

        //VS Repeat
        angularVsRepeat: 'common/modules/VSRepeat/angular-vs-repeat',

        //Poller module
        Poller: 'common/modules/Poller/pollerMod',

        //  BetTicket Module
        BetService: 'common/modules/BetService/betServiceBase',
        BetsSvc: 'common/modules/BetService/providers/betsSvcV2',
        BetNow: 'desktop/modules/BetNow/betNowMod',
        BetNowCtrl: 'desktop/modules/BetNow/controllers/betNowCtrl',
        MyBetsFilters: 'common/modules/BetService/myBetsFilters/myBetsFilters',
        StepSelectionsStringFilter: 'common/modules/BetService/filters/stepSelectionsStringFilter',

        //Features Module
        Features: 'common/modules/Features/featuresMod',
        FeaturesFac: 'common/modules/Features/providers/factories/featuresFac',
        FeaturesConfigController: 'common/modules/Features/controllers/featuresConfigController',

        // GTM
        GTMMod                                           : 'common/modules/GTM/gtmMod',
        GTMFac                                           : 'common/modules/GTM/providers/GTMFac',
        RegistrationPageGTMFac                           : 'common/modules/GTM/providers/registrationPageGTMFac',
        RacesPageGTMFac                                  : 'common/modules/GTM/providers/racesPageGTMFac',
        ScreenNames                                      : 'common/modules/GTM/constants/screenNames',
        SectionNames                                     : 'common/modules/GTM/constants/sectionNames',

        // BetSlip module
        Betslip: 'desktop/modules/BetSlip/betslipMod',
        AddToBetslipCtrl: 'desktop/modules/BetSlip/controllers/addToBetslipCtrl',
        BetslipCtrl: 'desktop/modules/BetSlip/controllers/betslipCtrl',
        BetslipFactory: 'desktop/modules/BetSlip/providers/betslipFactory',
        BetRunnerPicksSvc: 'desktop/modules/BetSlip/providers/betRunnerPicksSvc',

        //RaceInfo Module
        RaceInfo: 'common/modules/RaceInfo/raceInfoMod',
        RaceUrlFac: 'common/modules/RaceInfo/providers/raceUrlFac',
        RaceParamsEntity: 'common/modules/RaceInfo/entities/raceParamsEntity',
        RaceParamsEntityBuilder: 'common/modules/RaceInfo/builders/raceParamsEntityBuilder',
        RaceInfoFac: 'common/modules/RaceInfo/providers/raceInfoFac',
        RaceVideoFac: 'common/modules/RaceInfo/providers/raceVideoFac',
        RacesNavigationCtrl: 'desktop/modules/Races/controllers/racesNavigationCtrl',
        RaceMTP: 'common/modules/RaceInfo/filters/raceMTP',
        sortTracksAlphabetically: 'desktop/modules/Races/filters/sortTracksAlphabetically',
        RaceLinkFilter: 'common/modules/RaceInfo/filters/raceLinkFilter',
        RaceMtpStatusFac: 'common/modules/RaceInfo/providers/raceMtpStatusFac',

        //MTP module
        MTP             : 'common/modules/MTP/MTPMod',
        MTPDateFac      : 'common/modules/MTP/providers/factories/MTPDateFac',
        MTPCtrl         : 'common/modules/MTP/controllers/MTPController',

        // Program Page Race Selector Module
        ProgramPageRaceSelector: 'desktop/modules/ProgramPageRaceSelector/programPageRaceSelectorMod',
        ProgramPageRaceSelectorCtrl: 'desktop/modules/ProgramPageRaceSelector/controllers/programPageRaceSelectorCtrl',
        ProgramPageRaceSelectorSwipeableCtrl: 'desktop/modules/ProgramPageRaceSelector/controllers/programPageRaceSelectorSwipeableCtrl',
        RaceSelectorSwipeableModel: 'desktop/modules/ProgramPageRaceSelector/models/raceSelectorSwipeable',

        // Equibase Utils
        EquibaseUtils: 'desktop/modules/EquibaseUtils/equibaseUtilsMod',
        EquibaseTrackPastPerformanceButtonCtrl: 'desktop/modules/EquibaseUtils/controllers/equibaseTrackPastPerformanceButtonCtrl',

        //Races Module
        Races: 'desktop/modules/Races/racesMod',
        NextRacesBarCtrl: 'desktop/modules/Races/controllers/desktop/nextRacesBarCtrl',
        VideoCtrl: 'desktop/modules/Races/controllers/videoCtrl',
        VideoGridCtrl: 'desktop/modules/Races/controllers/videoGridCtrl',
        NextRacesItemsFac: 'desktop/modules/Races/components/nextRaceItem/factories/nextRacesItemsFac',
        NextRacesItemCtrl: 'desktop/modules/Races/components/nextRaceItem/controllers/nextRacesItemCtrl',
        NextRaceItemModel: 'desktop/modules/Races/components/nextRaceItem/models/nextRaceItemModel',
        NextRaceItemDir: 'desktop/modules/Races/components/nextRaceItem/directives/nextRaceItemDir',

        trackDetailsCtrl: 'desktop/modules/Races/controllers/trackDetails',
        RaceReplaysNavigationCtrl: 'desktop/modules/Races/controllers/raceReplaysNavigationCtrl',
        VideoRaceCtrl: 'desktop/modules/Races/controllers/videoRaceCtrl',
        RaceResultsCtrl: 'desktop/modules/Races/controllers/raceResultsCtrl',
        RaceReplaysCtrl: 'desktop/modules/Races/controllers/raceReplaysCtrl',
        RaceReplaysInfoHeaderCtrl: 'desktop/modules/RaceReplays/controllers/raceReplaysInfoHeaderCtrl',
        RaceChangesCtrl: 'desktop/modules/Races/controllers/desktop/raceChangesCtrl',
        RaceProgramCtrl: 'desktop/modules/Races/controllers/raceProgramCtrl',
        RaceProgramHeaderCtrl: 'desktop/modules/Races/controllers/raceProgramHeaderCtrl',
        RaceTimeformCtrl: 'desktop/modules/Races/controllers/raceTimeformCtrl',
        RaceTimeForm: 'desktop/modules/Races/controllers/models/raceTimeform',
        RaceTrackHubCtrl: 'desktop/modules/Races/controllers/raceTrackHubCtrl',

        CurrentLiveStreamListFac: 'desktop/modules/Races/providers/currentLiveStreamListFac',

        CarryOverPoolsCtrl: 'desktop/modules/Races/controllers/carryOverPoolsCtrl',

        TrackPerformanceProvider: 'desktop/modules/Races/providers/trackPerformanceProvider',
        RaceListFac: 'desktop/modules/Races/providers/raceListFac',
        TrackListFac: 'desktop/modules/Races/providers/trackListFac',

        RaceCtrl: 'desktop/modules/Races/controllers/raceCtrl',
        RaceInfoCtrl: 'desktop/modules/Races/controllers/raceInfoCtrl',
        RunnerReplayModalFac: 'desktop/modules/Races/providers/runnerReplayModalFac',
        RaceSvc: 'desktop/modules/Races/providers/raceSvc',
        TrackInfoFac: 'desktop/modules/Races/providers/trackInfoFac',
        TrackPerformanceListFac: 'desktop/modules/Races/providers/trackPerformanceListFac',
        ProgramPageRunnersFac: 'desktop/modules/Races/providers/programPageRunnersFac',
        GraphRaceFac: 'desktop/modules/Races/providers/graphRaceFac',
        RunnerInfoFac: 'desktop/modules/Races/providers/runnerInfoFac',

        HorizontalScrollDir: 'desktop/modules/Races/directives/horizontal-scroll-directive',
        TopScrollDir: 'desktop/modules/Races/directives/top-scroll-directive',

        RaceResultsDesktopDirectiveCtrl: 'desktop/modules/Races/controllers/desktop/raceResultsDesktopDirectiveCtrl',
        RaceResultsUseCase: 'desktop/modules/Races/useCases/raceResultsUseCases',
        RaceResultsGateway: 'desktop/modules/Races/gateways/raceResultsGateway',
        RaceInfoEntity: 'desktop/modules/Races/entities/raceInfoEntity',
        RaceStatusEntity: 'desktop/modules/Races/entities/raceStatusEntity',
        RaceRunnerEntity: 'desktop/modules/Races/entities/raceRunnerEntity',
        RaceResultsEntity: 'desktop/modules/Races/entities/raceResultsEntity',
        RaceResultsRunnerEntity: 'desktop/modules/Races/entities/raceResultsRunnerEntity',
        RaceResultsPayoffEntity: 'desktop/modules/Races/entities/raceResultsPayoffEntity',

        RaceInfoEntityBuilder: 'desktop/modules/Races/builders/raceInfoEntityBuilder',
        RaceStatusEntityBuilder: 'desktop/modules/Races/builders/raceStatusEntityBuilder',
        RaceRunnerEntityBuilder: 'desktop/modules/Races/builders/raceRunnerEntityBuilder',
        RaceResultsEntityBuilder: 'desktop/modules/Races/builders/raceResultsEntityBuilder',
        RaceResultsRunnerEntityBuilder: 'desktop/modules/Races/builders/raceResultsRunnerEntityBuilder',
        RaceResultsPayoffEntityBuilder: 'desktop/modules/Races/builders/raceResultsPayoffEntityBuilder',

        RaceResultsRunnersListDirectiveCtrl: 'desktop/modules/Races/controllers/desktop/raceResultsRunnersListDirectiveCtrl',

        RaceMTPFilter: 'desktop/modules/Races/filters/raceMTPFilter',

        GenericComparators: 'desktop/modules/Races/comparators/GenericComparators',
        RunnerComparators: 'desktop/modules/Races/comparators/RunnerComparators',

        MtpDisplayModel: 'desktop/modules/Races/controllers/models/mtpDisplay',

        RaceStatusUtils: 'common/modules/Utilities/tools/raceStatusUtils',
        RaceStatusLabelDir: 'desktop/modules/Races/directives/race-status-label-directive',
        HandleListNavigation: 'desktop/modules/Races/Utilities/libs/handleListNavigation',
        ManageNextRaces: 'desktop/modules/Races/Utilities/libs/manageNextRaces',

        RaceMtpLabelDir: 'desktop/modules/Races/components/raceMtpLabel/directives/raceMtpLabelDir',
        RaceMtpLabelMod: 'desktop/modules/Races/components/raceMtpLabel/raceMtpLabelMod',

        //paginated table
        HandlePagination: 'common/modules/Utilities/lib/HandlePagination',
        RaceTypeIDFilter: 'common/modules/Utilities/filters/raceTypeIDFilter',
        DistanceConverterFilter: 'common/modules/Utilities/filters/distanceConverterFilter',

        //Race Replay
        RaceReplays: 'desktop/modules/RaceReplays/raceReplaysMod',
        RaceReplayEntityBuilder: 'desktop/modules/RaceReplays/builders/raceReplayEntityBuilder',
        HorseEntityBuilder: 'desktop/modules/RaceReplays/builders/horseEntityBuilder',
        RaceReplayEntity: 'desktop/modules/RaceReplays/entities/raceReplayEntity',
        HorseEntity: 'desktop/modules/RaceReplays/entities/horseEntity',
        RaceReplaysGateway: 'desktop/modules/RaceReplays/gateways/raceReplaysGateway',
        RaceReplayItemCtrl: 'desktop/modules/RaceReplays/controllers/raceReplayItemCtrl',

        // Race Track Current Bets Module
        RaceTrackCurrentBetsMod: 'desktop/modules/RaceTrackCurrentBets/raceTrackCurrentBetsMod',
        GenericMessageDir: 'desktop/modules/RaceTrackCurrentBets/directives/generic-message',
        RunnersSelectionDir: 'desktop/modules/RaceTrackCurrentBets/directives/runners-selection-directive',
        RunnersSelectionCtrl: 'desktop/modules/RaceTrackCurrentBets/controllers/runnersSelectionCtrl',
        SelectionLegDir: 'desktop/modules/RaceTrackCurrentBets/directives/selection-leg-directive',
        SelectionPositionAndNotKeyBoxDir: 'desktop/modules/RaceTrackCurrentBets/directives/selection-position-and-not-key-box-directive',
        SelectionSimpleOrKeyBoxDir: 'desktop/modules/RaceTrackCurrentBets/directives/selection-simple-or-key-box-directive',
        RaceTrackCurrentBetsDir: 'desktop/modules/RaceTrackCurrentBets/directives/race-track-current-bets-directive',
        MyBetsCurrentRaceInput: 'desktop/modules/RaceTrackCurrentBets/models/myBetsCurrentRaceInput',
        MyBetsCurrentRaceInputBuilder: 'desktop/modules/RaceTrackCurrentBets/builders/myBetsCurrentRaceInputBuilder',
        OrdinalNumberStringFilter: 'desktop/modules/RaceTrackCurrentBets/filters/ordinalNumberStringFilter',
        WordBreakOpportunityStringFilter: 'desktop/modules/RaceTrackCurrentBets/filters/wordBreakOpportunityStringFilter',

        //Track Selector Module
        TrackSelectorCtrl: 'desktop/modules/Tracks/components/trackSelector/controllers/trackSelectorCtrl',
        TrackSelectorDir: 'desktop/modules/Tracks/components/trackSelector/directives/track-selector-directive',
        TrackSelectorItem: 'desktop/modules/Tracks/components/trackSelector/models/trackSelectorItem',
        TrackSelectorItemBuilder: 'desktop/modules/Tracks/components/trackSelector/models/builders/trackSelectorItemBuilder',
        TrackSelectorItemFactory: 'desktop/modules/Tracks/components/trackSelector/factories/trackSelectorItemFactory',
        TrackSelectorItemCurrentRaceFactory: 'desktop/modules/Tracks/components/trackSelector/factories/trackSelectorItemCurrentRaceFactory',
        TrackSelectorMod: 'desktop/modules/Tracks/components/trackSelector/trackSelectorMod',

        // Tracks Module
        Track: 'common/modules/Track/trackMod',
        TrackSvc: 'common/modules/Track/providers/trackSvc',

        // Security Module
        Security: 'common/modules/Security/securityMod',
        RegistrationContainerCtrl: 'common/modules/Security/controllers/registrationContainerCtrl',
        SecuritySvc: 'common/modules/Security/providers/securitySvc',
        UserSessionSvc: 'common/modules/Security/providers/userSessionSvc',
        GeoComplySvc: 'common/modules/Security/providers/geoComplySvc',
        GeoComplyLoginSvc: 'common/modules/Security/providers/geoComplyLoginSvc',
        PollingScheduler: 'common/modules/Security/providers/pollingScheduler',

        //Registration Module
        RegistrationPage: 'desktop/modules/RegistrationPage/registrationPageMod',
        RegistrationPageCtrl: 'desktop/modules/RegistrationPage/controllers/registrationPageCtrl',

        // Login Module
        Login: 'desktop/modules/Login/loginMod',
        LoginRequiredSvc: 'desktop/modules/Login/providers/loginRequiredSvc',

        // UserProfile Module
        UserProfile: 'common/modules/UserProfile/userProfileMod',
        UserProfileSvc: 'common/modules/UserProfile/providers/services/userProfileSvc',
        UserBalanceSvc: 'common/modules/UserProfile/providers/services/userBalanceSvc',
        UserPromotionsSvc: 'common/modules/UserProfile/providers/services/userPromotionsSvc',
        StatesSvc: 'common/modules/UserProfile/providers/services/statesSvc',
        PasswordValidatorSvc: 'common/modules/UserProfile/providers/helpers/passwordValidatorSvc',
        UsernameValidatorSvc: 'common/modules/UserProfile/providers/helpers/usernameValidatorSvc',
        ProfileHelperSvc: 'common/modules/UserProfile/providers/helpers/profileHelperSvc',
        QuickDepositCtrl: 'common/modules/UserProfile/controllers/quickDepositCtrl',

        //My Bets Service Module
        MyBetsService: 'common/modules/MyBetsService/myBetsServiceMod',
        MyBetsSvc: 'common/modules/MyBetsService/providers/myBetsSvc',

        //My Bets Module
        MyBets: 'desktop/modules/MyBets/myBetsMod',
        MyBetsCtrl: 'desktop/modules/MyBets/controllers/myBetsCtrl',
        MyBetsCancelBetCtrl: 'desktop/modules/MyBets/controllers/myBetsCancelBetCtrl',
        MyBetsCancelBetDir: 'desktop/modules/MyBets/directives/mybets-cancel-bet-dir',
        MyBetsDataTransformer: 'desktop/modules/MyBets/transformers/myBetsDataTransformer',
        GenericMessage: 'desktop/modules/MyBets/models/genericMessageModel',
        WagersDir: 'desktop/modules/MyBets/directives/wagers-dir',
        MyBetsTableDir: 'desktop/modules/MyBets/directives/my-bets-table-dir',
        MyBetsTableCtrl: 'desktop/modules/MyBets/controllers/myBetsTableCtrl',

        //PasswordRecoveryGtm
        PasswordRecoveryGtm: 'desktop/modules/PasswordRecovery/passwordRecoveryGtmMod',
        PasswordRecoveryGtmFac: 'desktop/modules/PasswordRecovery/factories/passwordRecoveryGtmFac',

        // Account Module
        Account: 'desktop/modules/Account/accountMod',
        AccountCtrl: 'desktop/modules/Account/controllers/accountCtrl',
        ReportsCtrl: 'desktop/modules/Account/controllers/reportsCtrl',
        AccountPanelCtrl: 'desktop/modules/Account/controllers/accountPanelCtrl',
        BetReportsCtrl: 'desktop/modules/Account/controllers/desktop/betReportsCtrl',
        AccountBettingPrefsCtrl: 'desktop/modules/Account/controllers/accountBettingPrefsCtrl',
        AccountChangePinCtrl: 'desktop/modules/Account/controllers/accountChangePinCtrl',
        AccountChangePasswordCtrl: 'desktop/modules/Account/controllers/accountChangePasswordCtrl',
        MyProfileChangePinCtrl: 'desktop/modules/Account/controllers/desktop/myProfileChangePinCtrl',
        StackViewDir: 'desktop/modules/Account/directives/stack-view-directive',
        BetDir: 'desktop/modules/Account/directives/bet-directive',
        QuickAccessCtrl: 'desktop/modules/Account/controllers/quickAccessCtrl',
        AccountHistoryCtrl: 'desktop/modules/Account/controllers/accountHistoryCtrl',
        AccountUserProfileCtrl: 'desktop/modules/Account/controllers/accountUserProfileCtrl',
        AccountMarketingCtrl: 'desktop/modules/Account/controllers/desktop/accountMarketingCtrl',
        MyFundsCtrl: 'desktop/modules/Account/controllers/desktop/myFundsCtrl',
        AboutYouCtrl: 'desktop/modules/Account/controllers/desktop/aboutYouCtrl',
        EditEmailCtrl: 'desktop/modules/Account/controllers/desktop/editEmailCtrl',
        AddressDetailsCtrl: 'desktop/modules/Account/controllers/desktop/addressDetailsCtrl',
        ContactDetailsCtrl: 'desktop/modules/Account/controllers/desktop/contactDetailsCtrl',
        ChangePasswordCtrl: 'desktop/modules/Account/controllers/desktop/changePasswordCtrl',
        EditUsernameCtrl: 'desktop/modules/Account/controllers/desktop/editUsernameCtrl',
        UserPreferencesCtrl: 'desktop/modules/Account/controllers/desktop/userPreferencesCtrl',

        RaceAlert: 'desktop/modules/Account/entities/raceAlert',
        StableEntity: 'desktop/modules/Account/entities/stableEntity',
        StableEntityType: 'desktop/modules/Account/entities/stableEntityType',
        SubscriptionAlerts: 'desktop/modules/Account/entities/subscriptionsAlerts',
        RaceAlertFactory: 'desktop/modules/Account/entities/factories/raceAlertFactory',
        StableEntityTypeFactory: 'desktop/modules/Account/entities/factories/stableEntityTypeFactory',
        SubscriptionAlertsFactory: 'desktop/modules/Account/entities/factories/subscriptionAlertsFactory',
        StableEntityBuilder: 'desktop/modules/Account/entities/builders/stableEntityBuilder',
        StableEntityTypeBuilder: 'desktop/modules/Account/entities/builders/stableEntityTypeBuilder',
        MyTracksCtrl: 'desktop/modules/Account/controllers/desktop/myTracksCtrl',
        MyFavoritesCtrl: 'desktop/modules/Account/controllers/desktop/myFavoritesCtrl',
        MyTracksDir: 'desktop/modules/Account/directives/my-tracks-dir',
        TodayAlertsDir: 'desktop/modules/Account/directives/today-alerts-dir',
        TodayAlertsCtrl: 'desktop/modules/Account/controllers/desktop/todayAlertsCtrl',
        RacesTodayFac: 'desktop/modules/Account/providers/factories/racesTodayFac',
        HeaderButtonDir: 'desktop/modules/Account/directives/header-button-directive',
        RaceTodayEntity: 'desktop/modules/Account/entities/raceTodayEntity',
        RaceTodayBuilder: 'desktop/modules/Account/entities/builders/raceTodayBuilder',
        PromotionsSvc: 'desktop/modules/Account/providers/services/promotionsSvc',

        // Account Summary
        AccountSummary: 'desktop/modules/AccountSummary/accountSummaryMod',
        AccountSummarySvc: 'desktop/modules/AccountSummary/providers/services/accountSummarySvc',
        AccountSummaryCategoryBuilder: 'desktop/modules/AccountSummary/entities/builders/accountSummaryCategoryBuilder',
        AccountSummaryDetailBuilder: 'desktop/modules/AccountSummary/entities/builders/accountSummaryDetailBuilder',
        AccountSummaryDetailEntity: 'desktop/modules/AccountSummary/entities/accountSummaryDetailEntity',
        AccountSummaryCategoryEntity: 'desktop/modules/AccountSummary/entities/accountSummaryCategoryEntity',
        AccountSummaryCtrl: 'desktop/modules/AccountSummary/controllers/accountSummaryCtrl',
        AccountSummaryWagerDetailsCtrl: 'desktop/modules/AccountSummary/controllers/accountSummaryWagerDetailsCtrl',
        AccountSummaryBasicDetailsCtrl: 'desktop/modules/AccountSummary/controllers/accountSummaryBasicDetailsCtrl',


        // My Stable
        NotificationMyStableCtrl: 'desktop/modules/Account/controllers/desktop/notificationMyStableCtrl',
        MyStableSvc: 'desktop/modules/Account/providers/services/myStableSvc',
        MyStableCtrl: 'desktop/modules/Account/controllers/desktop/myStableCtrl',
        MyStableDir: 'desktop/modules/Account/directives/my-stable-dir',
        MyStableFaqDir: 'desktop/modules/Account/directives/my-stable-faq-dir',
        MyStableFaqCtrl: 'desktop/modules/Account/controllers/desktop/myStableFaqCtrl',
        MyStableSettingsDir: 'desktop/modules/Account/directives/my-stable-settings-directive',
        AddToMyStableDir: 'desktop/modules/Account/directives/add-to-my-stable-directive',
        AddAlertsToMyStableCtrl: 'desktop/modules/Account/controllers/desktop/addAlertsToMyStableCtrl',
        MyStableSettingsCtrl: 'desktop/modules/Account/controllers/desktop/myStableSettingsCtrl',
        MyStableSearchDir: 'desktop/modules/Account/directives/my-stable-search-directive',
        MyStableSearchCtrl: 'desktop/modules/Account/controllers/desktop/myStableSearchCtrl',
        MyStableManageCtrl: 'desktop/modules/Account/controllers/desktop/myStableManageCtrl',
        MyStableRaceTodayCtrl: 'desktop/modules/Account/controllers/desktop/myStableRaceTodayCtrl',

        // Layout Module
        Layout: 'common/modules/Layout/layoutMod',
        LayoutDir: 'common/modules/Layout/directives/layout-directive',
        SwitchLayoutForDir: 'common/modules/Layout/directives/switch-layout-for-directive',
        LayoutFac: 'common/modules/Layout/providers/factories/layoutFac',
        MediatorSubscriptionsFac: 'common/modules/Layout/providers/factories/mediatorSubscriptionsFac',

        // Utilities Module
        RunBlockMediatorFactory: 'desktop/modules/RunBlockMediator/providers/factories/runBlockMediatorFactory',
        RunBlockMediator: 'desktop/modules/RunBlockMediator/runBlockMediatorMod',
        Utilities: 'common/modules/Utilities/utilitiesMod',
        YoutubeVideoDir: 'common/modules/Utilities/directives/youtube-video-directive',
        DrawerMenuButtonDir: 'common/modules/Utilities/directives/drawer-menu-button-directive',
        InfoDialogDir: 'common/modules/Utilities/directives/info-dialog-directive',
        PreventDefaultDir: 'common/modules/Utilities/directives/prevent-default-directive',
        InfiniteScrollDir: 'common/modules/Utilities/directives/infinite-scroll-directive',
        StatePersistenceFac: 'common/modules/Utilities/providers/factories/statePersistenceFac',
        CookieFac: 'common/modules/Utilities/providers/factories/cookieFac',
        LocalStorageFac: 'common/modules/Utilities/providers/factories/localStorageFac',
        PreferencesCookieFac: 'common/modules/Utilities/providers/factories/preferencesCookieFac',
        PreferencesCookieReloadedFac: 'common/modules/Utilities/providers/factories/preferencesCookieReloadedFac',
        FavoriteCookieFac: 'common/modules/Utilities/providers/factories/favoriteCookieFac',
        CustomerCookieFac: 'common/modules/Utilities/providers/factories/customerCookieFac',
        CalculatePurseFac: 'common/modules/Utilities/providers/factories/calculatePurseFac',
        FindModalContainerFac: 'common/modules/Utilities/providers/factories/findModalContainerFac',
        DistanceConverterFac: 'common/modules/Utilities/providers/factories/distanceConverterFac',
        YouTubeLoaderFac: 'common/modules/Utilities/providers/factories/YouTubeLoaderFac',
        FirstTimeHelperCtrl: 'common/modules/Utilities/controllers/firstTimeHelperCtrl',
        UserPropertiesSvc: 'common/modules/Utilities/providers/service/userPropertiesSvc',
        FeaturesHandlerSvc: 'common/modules/Utilities/providers/service/featuresHandlerSvc',
        NewWindowGridFac: 'common/modules/Utilities/providers/factories/newWindowGridFac',
        LocationUnreloadFac: 'common/modules/Utilities/providers/factories/locationUnreloadFac',
        AmountOptionsFac: 'common/modules/Utilities/providers/factories/amountOptionsFac',
        GetNestedPropertyValueFilter: 'common/modules/Utilities/filters/getNestedPropertyValueFilter',
        GetRelativeDayBasedOnGivenDateFilter: 'common/modules/Utilities/filters/getRelativeDayBasedOnGivenDateFilter',
        DateTimeFormatters: 'common/modules/Utilities/formatters/dateTimeFormatters',
        DateComparators: 'common/modules/Utilities/formatters/dateComparators',
        MdrCompileHtml: 'common/modules/Utilities/directives/mdr-compile-html',
        HtmlCompile: 'common/modules/Utilities/directives/html-compile',
        RoundAmountFilter: 'common/modules/Utilities/filters/roundAmountFilter',
        ReverseFilter: 'common/modules/Utilities/filters/reverseFilter',
        WagerProfileFac: 'common/modules/Utilities/providers/factories/wagerProfileFac',
        TvgHostsFac: 'common/modules/Utilities/providers/factories/tvgHostsFac',
        BrowserCheckFac: 'common/modules/Utilities/providers/factories/browserCheckFac',
        RaceMtpDisplay: 'common/modules/Utilities/tools/raceMtpDisplay',
        SignalProviderFac: 'common/modules/Utilities/providers/factories/SignalProviderFac',
        MathAbsoluteValueFilter: 'common/modules/Utilities/filters/mathAbsoluteValueFilter',


        //Video
        Video: 'desktop/modules/Video/videoMod',
        VideoPopOutFac: 'desktop/modules/Video/providers/videoPopOutFac',

        //Reusable Components
        ReusableComponents: 'desktop/modules/ReusableComponents/reusableComponentsMod',
        PaginatedTableDir: 'desktop/modules/ReusableComponents/grid/paginated-table-directive',
        CompileFieldTemplateDir: 'desktop/modules/ReusableComponents/grid/compile-field-template-directive',

        TimeUtilFac: 'common/modules/Utilities/providers/factories/timeUtilFac',
        TrackListFromMetadataFac: 'common/modules/Utilities/providers/factories/trackListFromMetadataFac',
        ManageFavoritesFac: 'common/modules/Utilities/providers/factories/manageFavoritesFac',

        // Modal Window Module
        ModalWindow: 'desktop/modules/ModalWindow/modalWindowMod',
        NewWindowDir: 'desktop/modules/ModalWindow/directives/new-window-dir',
        ResizableDir: 'desktop/modules/ModalWindow/directives/resizable-dir',
        ResizableCustomDir: 'desktop/modules/ModalWindow/directives/resizableCustom-dir',
        DraggableDir: 'desktop/modules/ModalWindow/directives/draggable-dir',
        ModalPersistentCtrl: 'desktop/modules/ModalWindow/controllers/modalPersistentCtrl',

        // Talent Picks Module
        TalentPicksBase: 'common/modules/TalentPicks/talentPicksBase',
        TalentPicksSvc: 'common/modules/TalentPicks/providers/talentPicksSvc',

        TalentPicksEventsFactory: 'desktop/modules/TalentPicks/providers/talentPicksEventsFactory',
        TalentPicksFactory: 'desktop/modules/TalentPicks/providers/talentPicksFactory',
        TalentPicks: 'desktop/modules/TalentPicks/talentPicksMod',
        TalentPicksCtrl: 'desktop/modules/TalentPicks/controllers/talentPicksCtrl',
        RaceTalentPicksCtrl: 'desktop/modules/TalentPicks/controllers/raceTalentPicksCtrl',

        // Widgets Module
        Widgets: 'common/modules/Widgets/widgetsMod',
        WidgetsCtrl: 'common/modules/Widgets/controllers/widgetsCtrl',
        WidgetsFac: 'common/modules/Widgets/providers/factories/widgetsFac',

        // Custom CMS Pages
        CMSPages: 'common/modules/CMSPages/cmsPagesMod',
        CMSPagesCtrl: 'common/modules/CMSPages/controllers/cmsPagesCtrl',

        WidgetsDesktop: 'desktop/modules/WidgetsDesktop/widgetsDesktopMod',
        TopBarNavigationDir: 'desktop/modules/WidgetsDesktop/directives/top-bar-navigation-directive',
        BetSlipOpenerHelperDir: 'desktop/modules/WidgetsDesktop/components/betslipOpenerHelper/directives/betslipOpenerHelperDir',
        BetSlipOpenerHelperCtrl: 'desktop/modules/WidgetsDesktop/components/betslipOpenerHelper/controllers/betslipOpenerHelperCtrl',
        BetSlipOpenerHelperFac: 'desktop/modules/WidgetsDesktop/components/betslipOpenerHelper/services/betslipOpenerHelperFactory',
        OnScrollDir: 'desktop/modules/WidgetsDesktop/directives/on-scroll-directive',
        DropdownHoverDir: 'desktop/modules/WidgetsDesktop/directives/dropdown-hover-directive',
        AlertBoxDir: 'desktop/modules/WidgetsDesktop/directives/alert-box-directive',
        PromoWidgetDir: 'desktop/modules/WidgetsDesktop/directives/promo-widget-directive',
        LoginRedirectFac: 'desktop/modules/WidgetsDesktop/components/loginRedirect/factories/LoginRedirectFac',
        TvgAutocompleteCtrl: 'desktop/modules/WidgetsDesktop/components/autocomplete/controllers/tvgAutocompleteCtrl',
        TvgAutocompleteDir: 'desktop/modules/WidgetsDesktop/components/autocomplete/directives/tvg-autocomplete-directive',
        TvgAutocompleteMod: 'desktop/modules/WidgetsDesktop/components/autocomplete/autocompleteMod',

        // Carousel
        Carousel: 'desktop/modules/WidgetsDesktop/components/carousel/carouselMod',
        CarouselDir: 'desktop/modules/WidgetsDesktop/components/carousel/directives/carousel-directive',
        WindowUtils: 'desktop/modules/WidgetsDesktop/components/carousel/windowUtils',

        // Datepicker
        DatePicker: 'desktop/modules/WidgetsDesktop/components/datePicker/datePickerMod',
        DatePickerDir: 'desktop/modules/WidgetsDesktop/components/datePicker/directives/datepicker-directive',

        //Handicapping
        Handicapping: 'desktop/modules/Handicapping/handicappingMod',
        HandicappingCtrl: 'desktop/modules/Handicapping/controllers/handicappingCtrl',
        HandicappingByUrlCtrl: 'desktop/modules/Handicapping/controllers/handicappingByUrlCtrl',
        HandicappingStoreCtrl: 'desktop/modules/Handicapping/controllers/handicappingStoreCtrl',
        HandicappingStoreFac: 'desktop/modules/Handicapping/providers/factories/handicappingStoreFac',
        HandicappingRaceReplaysFac: 'desktop/modules/Handicapping/providers/factories/handicappingRaceReplaysFac',
        HandicappingRaceReplaysCtrl: 'desktop/modules/Handicapping/controllers/handicappingRaceReplaysCtrl',
        RaceReplaysSearchByDateCtrl: 'desktop/modules/Handicapping/controllers/raceReplaysSearchByDateCtrl',
        RaceReplaysSearchByTrackCtrl: 'desktop/modules/Handicapping/controllers/raceReplaysSearchByTrackCtrl',
        RaceReplaysSearchByHorseCtrl: 'desktop/modules/Handicapping/controllers/raceReplaysSearchByHorseCtrl',
        RaceReplaysSearchByFavoriteCtrl: 'desktop/modules/Handicapping/controllers/raceReplaysSearchByFavoriteCtrl',

        //Help
        Help: 'desktop/modules/Help/helpMod',
        HelpCtrl: 'desktop/modules/Help/controllers/helpCtrl',
        TutorialVideosCtrl: 'desktop/modules/Help/controllers/tutorialVideosCtrl',
        TutorialVideosSvc: 'desktop/modules/Help/providers/tutorialVideosSvc',
        ResourceCenterCtrl: 'desktop/modules/Help/controllers/resourceCenterCtrl',

        //FeaturedTracks
        FeaturedTracks: 'desktop/modules/FeaturedTracks/featuredTracksMod',
        FeaturedTracksDir: 'desktop/modules/FeaturedTracks/directives/featuredTracksDir',
        FeaturedTrackElementDir: 'desktop/modules/FeaturedTracks/directives/featuredTrackElementDir',
        FeaturedTracksCtrl: 'desktop/modules/FeaturedTracks/controllers/featuredTracksCtrl',
        FeaturedTracksFac: 'desktop/modules/FeaturedTracks/providers/featuredTracksFac',
        FeaturedTrackModel: 'desktop/modules/FeaturedTracks/models/featuredTrackModel',

        // External Components
        ExternalComponents: 'common/modules/ExternalComponents/externalComponentsProviderMod',
        ExternalComponentsSvc: 'common/modules/ExternalComponents/providers/externalComponentsSvc',

        //Coming Soon
        ComingSoon              : 'desktop/modules/ComingSoon/comingSoonMod',
        ComingSoonHomepageDir   : 'desktop/modules/ComingSoon/directives/coming-soon-homepage-directive',
        ComingSoonItemDir       : 'desktop/modules/ComingSoon/directives/coming-soon-item-directive',
        ComingSoonCtrl          : 'desktop/modules/ComingSoon/controllers/comingSoonCtrl',
        ComingSoonSvc           : 'desktop/modules/ComingSoon/providers/comingSoonSvc',

        //Full Schedule Reloaded
        FullScheduleReloaded: 'desktop/modules/FullScheduleReloaded/fullScheduleReloadedMod',
        FullScheduleReloadedCtrl: 'desktop/modules/FullScheduleReloaded/controllers/fullScheduleReloadedCtrl',
        FullScheduleReloadedByTrackRaceList: 'desktop/modules/FullScheduleReloaded/directives/byTrackRaceListDir',
        FullScheduleReloadedUpdateFavoriteFeedbackAction: 'desktop/modules/FullScheduleReloaded/directives/updateFavoriteActionFeedback',
        FullScheduleReloadedFac: 'desktop/modules/FullScheduleReloaded/factories/fullScheduleReloadedFac',
        FullScheduleReloadedModel: 'desktop/modules/FullScheduleReloaded/models/fullScheduleReloaded',

        tvgTemplates: 'desktop/tvg.templates',

        // SEO
        SEO: 'common/modules/SEO/seoMod',
        PageMetaInfoFac: 'common/modules/SEO/providers/pageMetaInfoFac',
        PageMetaInfoEntity: 'common/modules/SEO/entities/pageMetaInfoEntity',
        PageMetaInfoEntityBuilder: 'common/modules/SEO/builders/pageMetaInfoEntityBuilder',
        SeoLifeCycleManagerSvc: 'common/modules/SEO/providers/seoLifeCycleManagerSvc',
        CurrentPageMetaInfoSingleton: 'common/modules/SEO/providers/currentPageMetaInfoSingleton',
        SeoMetaDescriptionDirective: 'common/modules/SEO/directives/seo-meta-description-directive',
        SeoPageTitleDirective: 'common/modules/SEO/directives/seo-page-title-directive',

        // Video Provider
        VideoProvider: 'common/modules/VideoProvider/videoProviderMod',
        VideoProviderSvc: 'common/modules/VideoProvider/providers/videoProviderSvc',

        //Favorite Tracks
        FavoriteTracks: 'common/modules/FavoriteTracks/favoriteTracksMod',
        FavoriteTracksFac: 'common/modules/FavoriteTracks/providers/favoriteTracksFac',

        //Preferences
        Preferences: 'common/modules/Preferences/preferencesMod',
        PreferencesFac: 'common/modules/Preferences/providers/preferencesFac',
        PreferencesEntity: 'common/modules/Preferences/entities/preferencesEntity',
        PreferencesEntityBuilder: 'common/modules/Preferences/builders/preferencesEntityBuilder',

        //Features JSON
        defaultFeatures: 'common/defaultFeatures',

        //SiteTabs
        SiteTabs:     'desktop/modules/SiteTabs/siteTabsMod',
        SiteTabsDir:  'desktop/modules/SiteTabs/directives/siteTabsDir',
        SiteTabsCtrl: 'desktop/modules/SiteTabs/controllers/siteTabsCtrl',

        //FooterLinks
        FooterLinks:     'desktop/modules/FooterLinks/footerLinksMod',
        FooterLinksDir:  'desktop/modules/FooterLinks/directives/footerLinksDir',
        FooterLinksCtrl: 'desktop/modules/FooterLinks/controllers/footerLinksCtrl',

        //Navigation Menu
        NavigationMenu:    'desktop/modules/NavigationMenu/navigationMenuMod',
        NavigationMenuDir: 'desktop/modules/NavigationMenu/directives/navigationMenuDir',
        CustomerSupportDir:'desktop/modules/NavigationMenu/directives/customerSupportDir',
        CustomerSupportCtrl:'desktop/modules/NavigationMenu/controllers/customerSupportCtrl',

        // GTM factory decorator
        GTMFacDecorator: 'desktop/modules/GTM/providers/gtmFacDecorator',
        GTMDesktopMod: 'desktop/modules/GTM/gtmDesktopMod',

        // Logging module
        Logging: 'common/modules/Logging/loggingMod',
        VideosLoggingFac: 'common/modules/Logging/providers/videosLoggingFac',

        //Notification Module
        Notifications: 'desktop/modules/Notification/NotificationMod',
        ScrollDecorator: 'desktop/modules/Notification/decorators/NotificationDecorator',

        // Unsubscribe
        Unsubscribe: 'desktop/modules/Unsubscribe/unsubscribeMod',
        UnsubscribeCtrl: 'desktop/modules/Unsubscribe/controllers/unsubscribeCtrl',

        //Common
        initData: 'common/initData',
        neulion: 'common/vendors/neulion',
        swfobject: 'common/vendors/swfobject'
    },
    shim: {
            angular: { exports: 'angular' },
            lodash: { exports: '_' },
            uuid: { exports: 'uuid' },
            muton: { exports: 'muton' },
            angularRoute: ['angular'],
            angularTouch: ['angular'],
            angularAnimate: ['angular'],
            ngMd5: ['angular'],
            angularCookies: ['angular'],
            angularCookie: ['angular'],
            angularUTF8Base64: ['angular'],
            angularVsRepeat: ['angular'],
            uibootstrap: ['angular'],
            uibootstraptpls: ['uibootstrap'],
            Poller: ['angular'],
            neulion: ['swfobject'],
            Deposit: ['angular'],
            Configuration: ['angular', 'matchMedia'],
            Content: ['angular'],
            Registration: ['angular'],
            angularSanitize: ['angular'],
            PasswordRecovery: ['angularSanitize'],
            ngDialog: ['angular'],
            angularFilter: ['angular'],
            TVGCommon: ['angular'],
            appDesktop: [
                'initData',
                'defaultFeatures',
                'Poller',
                'neulion',
                'angularRoute',
                'angularVsRepeat',
                'uibootstraptpls',
                'angularTouch',
                'angularSanitize',
                'angularAnimate',
                'angularCookies',
                'angularCookie',
                'angularUTF8Base64',
                'moment',
                'lodash',
                'uuid',
                'ngMd5',
                'muton',
                'ngDialog',
                'angularFilter',
                'TVGCommon',
                'Deposit'
            ]
    },
    priority: ['angular'],
    deps: ['./desktop/bootstrap']
});

define("desktop/main", function(){});

